import React, { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import useToken from '../useToken';
import './Ventas.css';
import defaultImg from '../img/default.png';

const Ventas = ()=>{

	const { token, setToken } = useToken();
	const [buscarP, setBuscarP] = useState('');
	const [buscar, setBuscar] = useState('');
	const [save, setSave] = useState(true);
	const [modal, setModal] = useState(false);
	const [modalB, setModalB] = useState(false);
	const [modalC, setModalC] = useState(false);
	const [ventas, setVentas] = useState([]);
	const [info, setInfo] = useState({});
	const [productos, setProductos] = useState([]);
	const [ofertas, setOfertas] = useState([]);
	const [aplicadas, setAplicadas] = useState([]);
	const [producto, setProducto] = useState({});
	const [resultados, setResultados] = useState([]);
	const [cambio, setCambio] = useState(0);
	const [totalV, setTotalV] = useState(0);
	const [imagen, setImagen] = useState({});
	const [tipoP, setTipoP] = useState('efectivo');
	const [tipoT, setTipoT] = useState({tipoT:'Visa', comision:3});
	window.codigo = "";
	const pago = useRef(null);
	const refCantidad = useRef(null);
	const refCantidadDinero = useRef(null);
	var timeout = false;

	const total = {
		cantidad:0,
		total:0,
	};

	useEffect(()=>{
		getData();
	},[])

	useEffect(()=>{
		if(!modal && !modalB && !modalC && productos.length!=0){
			let div = document.querySelector(".tablaProductos div:nth-last-child(2)");
			while(div){
				if(div.className==""){
					div.focus();
					break;
				}
				else
					div = div.previousElementSibling;
			}
		}
	},[modalC, modal,  modalB])

	useEffect(()=>{
		setTotalV(total["total"]);
		if(productos.length!=0){
			let div = document.querySelector(".tablaProductos div:nth-last-child(2)");
			while(div){
				if(div.className==""){
					div.focus();
					break;
				}
				else
					div = div.previousElementSibling;
			}
		}
		const of = [];
		productos.forEach(producto=>{
			if(producto?.ofertas?.length!=0){
				producto?.ofertas?.forEach(oferta=>{
					if(of.find(o=>o.id_oferta==oferta.id_oferta)){}
					else{
						of.push(oferta);
					}
				})
			}
		})
		console.log(of);
		setOfertas(of);
	},[productos])

	useEffect(()=>{
		if(info.id_venta){
			setProductos(info["productos"]);
			setAplicadas(info["aplicadas"]);
		}
	},[info])

	useEffect(()=>{
		console.log(ofertas);
		const auxAplicadas = [];
		const productosAux = JSON.parse(JSON.stringify(productos));
		ofertas.forEach((oferta, i)=>{
			/*
				ver cuantas veces se cumple para cada producto
				luego elegir la minima
				oferta.veces = min(arr);
				--caso para n, m, l donde n < m < l;
					si un mismo producto clasifica para varias ofertas con distintas cantidad, siempre elegir la que tiene mayor cantidad;
					un producto solo puede aplicar a una promoción ¿?
			*/
			let veces = [];
			var nocumple = oferta.productos.some(prod=>{ //todos deben ser false
				var aplica = productosAux.some(p =>{ //si encuentra y aplica true; si no encuentra o no aplica, siguiente.
					if(p.id_producto==prod.id_producto){
						if(p.cantidad*1 >= prod.cantidad*1){//cumple
							veces.push(Math.floor(p.cantidad/prod.cantidad));
							p.cantidad = p.cantidad % prod.cantidad;
							return true;
						}
						else{
							veces = [];
							return false;
						}
						return false;
					}
					return false;
				});
				return !aplica;
			})
			console.log(veces);
			if(!nocumple){//sicumple
				auxAplicadas.push({...oferta, veces: Math.min(...veces)});
			}
			console.log(auxAplicadas);
		})
		setAplicadas(auxAplicadas);
	}, [ofertas])

	useEffect(()=>{
		console.log("aplicadas", aplicadas)
		let descuento = 0;
		aplicadas?.forEach(oferta=>{
			let original = 0;
			oferta.productos.forEach(prod=>{
				let p = productos.find(p1 => p1.id_producto==prod.id_producto);
				if(p){
					original += p.precio*prod.cantidad;
				}
			})
			oferta.desc = (original - oferta.descuento)*oferta.veces;
			descuento += (original - oferta.descuento)*oferta.veces;
		})
		console.log(total, descuento);
		total["total"] = total.total - descuento;
		setTotalV(total.total);
	},[aplicadas])

	window.onkeydown = (e)=>{
		if(modalB && e.target.tagName=="BODY"){
			if(e.key=="ArrowDown"){
				if(document.querySelector(".tablaProductos.busq>div:nth-child(2)"))
					document.querySelector(".tablaProductos.busq>div:nth-child(2)").focus();
				else
					document.querySelector(".modal .busqueda input").focus();
			}	
		}
		if(modal){
			if(e.key=="Escape"){
				setModal(false);
				return true;
			}
			if(document.activeElement.tagName=="INPUT" && document.activeElement.type=="number"){
				return true
			}
			if(document.activeElement.tagName=="BODY" && (e.key=="ArrowUp" || e.key=="ArrowLeft" || e.key=="ArrowRight" || e.key=="ArrowDown")){
				if(tipoP=="efectivo" && e.key=='ArrowRight'){
					setTipoP('tarjeta')
				}
				else if(tipoP=="tarjeta" && e.key=='ArrowLeft'){
					setTipoP('efectivo')
				}
				else if(tipoP=="efectivo" && (e.key=='ArrowDown' || e.key=='ArrowUp')){
					pago.current.focus();
				}
				else if(tipoP=="tarjeta" && (e.key=='ArrowDown' || e.key=='ArrowUp')){
					document.querySelector(".botonR.confirmar").focus();
				}
			}
			return false;
		}
		if(e.code=="Escape"){
			setModal(false);
			setModalB(false);
			setModalC(false);
		}
		else if(e.key=="ArrowDown"){
			if(productos.length && e.target.tagName!="INPUT"){
				if(document.activeElement.parentNode && (document.activeElement.parentNode.classList.contains("tablaProductos") || document.activeElement.parentNode.classList.contains("botones"))){
				}
				else{
					document.querySelector(".tablaProductos>div:not(.encabezados)").focus();
				}
			}
		}
		else if(e.key=="ArrowUp"){
			if(productos.length && e.target.tagName!="INPUT"){
				if(document.activeElement.parentNode && (document.activeElement.parentNode.classList.contains("tablaProductos") || document.activeElement.parentNode.classList.contains("botones"))){
				}
				else{
					let div = document.querySelector(".tablaProductos div:nth-last-child(2)");
					while(div){
						if(div.className==""){
							div.focus();
							break;
						}
						else
							div = div.previousElementSibling;
					}
				}
			}
		}
		else if(e.code=="Space" && !modal && !modalB && !modalC){
			setBuscarP('');
			setResultados([]);
			setModalB(true);
			setModalB(true);
			return false;
		}
		if(modal || modalB || modalC){
			return true;
		}
		if(!timeout){
			timeout = setTimeout(async function(){
				if(window.codigo.length>4){
					if(info?.id_venta){
						setInfo({});
					}
					const prod = productos?.findIndex(prod=>{
						return prod.codigo == window.codigo;
					});
					if(prod!==undefined && prod!=-1 && !info?.id_venta){
						if(productos[prod].cantidad*1 + 1 <= productos[prod].maximo){
							productos[prod].cantidad = productos[prod].cantidad*1 + 1;
							productos[prod].total = productos[prod].cantidad*1*productos[prod].precio;
							setProductos([...productos]);
						}
					}
					else{
						timeout = false;
						console.log(window.codigo)
						await fetch("https://www.utopia-tienda.shop/utopia/vendedor/buscaProducto.php?codigo="+window.codigo, 
							{
								method: 'GET',
								headers: {
									'Authorization': `Bearer ${token}`, // notice the Bearer before your token
								},
							}
						).then((response) => {/*console.log(response.text());*/return response.json()})
						.then((json) => {
							if(json["errAuth"]){
								// setToken({})
								return false;
							}
							if(json["producto"] && json["producto"]["maximo"] && json["producto"]["maximo"]!=0){
								json["producto"]["cantidad"] = json["producto"]["maximo"] < 1 ? json["producto"]["maximo"] : 1;
								json["producto"]["total"] = json["producto"]["precio"];
								if(info?.id_venta)
									setProductos([json["producto"]]);
								else{
									productos.push(json["producto"]);
									setProductos([...productos]);
								}
							}
							else{
								// error al escanear o no existe.
								// setProducto({nuevo:true, id_producto: uuidv4(), codigo:codigo})
							}
						})
						.catch((error) => {
							console.log(error);
						});
					}
				} //es del escaner
				else{ //es teclado
				}
				window.codigo="";
				timeout = false;
			}, 200)
		}
		if(e.key.length==1 && e.key.match(/[a-z0-9]/i)){
			// setCodigo(codigo+e.key);
			window.codigo+=e.key;
		}
	}

	async function getData(){
		fetch('https://www.utopia-tienda.shop/utopia/vendedor/getVentas.php', 
			{
				headers: {
					'Content-type': 'application/json',
					'Authorization': `Bearer ${token}`,
				},
			}
		).then((response) => {/*console.log(response.text());*/return response.json()})
		.then((json) => {
			if(json["errAuth"]){
				setToken({})
				return false;
			}
			console.log(json);
			setVentas(json["ventas"]);
		})
		.catch((error) => {
			console.log(error);
		});
	}

	function buscarCodigo(){
		fetch("https://www.utopia-tienda.shop/utopia/vendedor/buscaProductoN.php?codigo="+buscarP, 
			{
				method: 'GET',
				headers: {
					'Authorization': `Bearer ${token}`, // notice the Bearer before your token
				},
			}
		).then((response) => {/*console.log(response.text());*/return response.json()})
		.then((json) => {
			if(json["errAuth"]){
				// setToken({})
				return false;
			}
			console.log(json);
			if(json["producto"]){
				setResultados(json["producto"]);
			}
			else{
				setResultados([]);
			}
		})
		.catch((error) => {
			console.log(error);
		});
	}

	function guardar(){
		if(!save)
			return false;
		setSave(false);
		let data = {};
		data["tipoP"] = tipoP;
		if(tipoP=='efectivo'){
			data["recibido"] = pago.current.value;
			data["cambio"] = cambio;
		}
		else{
			data["recibido"] = 0;
			data["cambio"] = 0;
			data["tipoT"] = tipoT.tipoT;
			data["comision"] = tipoT.comision;
		}
		data["productos"] = productos;

		data["ofertas"] = aplicadas;
		fetch('https://www.utopia-tienda.shop/utopia/vendedor/completar.php?token=', {
			method: 'POST',
			headers: {
				'Content-type': 'application/json',
				'Authorization': `Bearer ${token}`, // notice the Bearer before your token
			},
			body: JSON.stringify(data)
		}).then((response) => {/*console.log(response.text());*/return response.json()})
		.then((json) => {
			if(json["errAuth"]){
				// setToken({})
				return false;
			}
			data["id_venta"] = json["id_venta"];
			data["id"] = json["id"];
			data["fecha"] = json["fecha"];
			setVentas([data, ...ventas])
			setProductos([]);
			setOfertas([]);
			setModal(false);
			setCambio(0);
			setTipoP('efectivo')
			setTipoT({tipoT:'Visa', comision:3});
			alert("Venta realizada.")
			setSave(true);
		})
		.catch((error) => {
			console.log(error);
			setSave(true);
		});
	}

	function cierraModal(e){
		if(e.target===e.currentTarget){
			setModal(false);
			setModalB(false);
			setModalC(false);
		}
	}

	function controles(e){
		if(e.key=="ArrowDown"){
			if(e.target.nextElementSibling && !e.target.nextElementSibling.classList.contains("encabezados") && !e.target.nextElementSibling.classList.contains("oferta")){
				e.target.nextElementSibling.focus();
			}
			else{
				if(e.target.parentNode.classList.contains("busq")){

				}
				else if(!e.target.classList.contains("cancel"))
					document.querySelector(".contTickets .botones .finalizar").focus();
			}
		}
		else if(e.key=="ArrowUp"){
			if(e.target.previousElementSibling){
				if(e.target.parentNode.classList.contains("busq") && e.target.previousElementSibling.classList.contains("encabezados")){
					document.querySelector(".modal .busqueda input").focus();
				}
				e.target.previousElementSibling.focus();
			}
			else if(e.target.classList.contains("finalizar")){
				let div = document.querySelector(".tablaProductos div:nth-last-child(2)");
				while(div){
					if(div.className==""){
						div.focus();
						break;
					}
					else
						div = div.previousElementSibling;
				}
			}
		}
		else if(e.key=="ArrowLeft"){
			if(e.target.classList.contains("finalizar") && !e.target.previousElementSibling){
				document.querySelector(".busq div:nth-last-child(2)").focus();
			}
			else if(e.target.previousElementSibling && e.target.previousElementSibling.classList.contains("finalizar")){
				e.target.previousElementSibling.focus();
			}
			else if(e.target.previousElementSibling && e.target.previousElementSibling.classList.contains("agregar")){
				e.target.previousElementSibling.focus();
			}
		}
		else if(e.key=="ArrowRight"){
			if(e.target.classList.contains("finalizar") || e.target.classList.contains("agregar")  && e.target.nextElementSibling){
				e.target.nextElementSibling.focus();
			}
		}
		else if(e.key=="Enter" && (window.codigo < 4 || e.target.parentNode.classList.contains("busq"))){
			e.target.click()
		}
		else if(e.key=="Delete"){
			if(e.currentTarget.querySelector(".eliminar")){
				e.currentTarget.querySelector(".eliminar").click();
			}
		}
	}

	function controlesTarjeta(e){
		if(e.key=="ArrowRight" && e.target.nextElementSibling){
			e.target.nextElementSibling.focus();
		}
		else if(e.key=="ArrowLeft" && e.target.previousElementSibling){
			e.target.previousElementSibling.focus();
		}
		else if(e.key=="Enter"){
			e.target.click();
			e.target.focus();
		}
		else if(e.key=="ArrowUp"){
			document.querySelector(".tipoPago>label:last-child").focus();
		}
		else if(e.key=="ArrowDown"){
			document.querySelector(".botonR.confirmar").focus();
		}
	}

	function eliminar(e, id){
		setProductos(productos.filter(prod=>{
			return prod.id_producto != id;
		}))
	}

	return(
		<>
			{(modal || modalB || modalC) &&
				<div className='modal' onClick={cierraModal}>
				{modal &&
					<div className='modalPago'>
						<div className='tipoPago'>
							<label tabIndex='0' onKeyDown={(e)=>{
								if(e.key=="ArrowRight"){
									e.target.nextElementSibling.click();
									e.target.nextElementSibling.focus();
								}
								else if(e.key=='ArrowDown'){
									pago.current.focus();
								}
							}}
							autoFocus={tipoP=='efectivo'}
							>
								<input type='radio' value='efectivo' checked={tipoP=='efectivo'} onChange={(e)=>{
									if(e.target.checked){
										setTipoP('efectivo')
									}
								}}/>
								<span>Efectivo</span>
							</label>
							<label tabIndex='0' onKeyDown={(e)=>{
								if(e.key=="ArrowLeft"){
									e.target.previousElementSibling.click();
									e.target.previousElementSibling.focus();
								}
								else if(e.key=='ArrowDown'){
									document.querySelector(".cambioTarjeta label").focus();
								}
							}}
							autoFocus={tipoP=='tarjeta'}
							>
								<input type='radio' value='tarjeta' checked={tipoP=='tarjeta'} onChange={(e)=>{
									if(e.target.checked){
										setTipoP('tarjeta')
									}
								}}/>
								<span>Tarjeta de Crédito/Débito</span>
							</label>
						</div>
						{tipoP=='efectivo' &&
							<div className='cambio cSaber'>
								<div>Ingresa la Cantidad Pagada</div>
								<div>
									$ <input type='number' className='inputBasic' ref={pago} autoFocus={true} onFocus={(e)=>{e.target.select()}} onKeyDown={(e)=>{
										if(e.key=="ArrowRight"){
											document.querySelector(".tipoPago>label:last-child").focus();
											document.querySelector(".tipoPago>label:last-child").click();
										}
										else if(e.key=='ArrowDown'){
											e.preventDefault();
											document.querySelector(".botonR.confirmar").focus();
										}
									}}
									onChange={(e)=>{
										setCambio(e.target.value - totalV);
									}}
									defaultValue={totalV || 0}/>
									<div className='cantCambio'>
										<span>Cambio: $ </span>
										<span>{cambio}</span>
									</div>
								</div>
							</div>
						}
						{tipoP=='tarjeta' &&
							<>
								<div className='cambioTarjeta'>
									<div>Selecciona el proveedor:</div>
									<div>
										<label tabIndex="0" onKeyDown={controlesTarjeta}>
											<input type='radio' name='coms' value='Visa' checked={tipoT.tipoT=="Visa"} onChange={(e)=>{
												if(e.target.checked){
													setTipoT({tipoT : e.target.value, comision: 3});
												}
											}}/>
											<span>Visa o MasterCard</span>
										</label>
										<label tabIndex="0" onKeyDown={controlesTarjeta}>
											<input type='radio' name='coms' value='American' checked={tipoT.tipoT=="American"} onChange={(e)=>{
												if(e.target.checked){
													setTipoT({tipoT : e.target.value, comision: 4});
												}
											}}/>
											<span>American Express</span>
										</label>
										<label tabIndex="0" onKeyDown={controlesTarjeta}>
											<input type='radio' name='coms' value='Otras' checked={tipoT.tipoT=="Otras"} onChange={(e)=>{
												if(e.target.checked){
													setTipoT({tipoT : e.target.value, comision: 6});
												}
											}}/>
											<span>Otras dos</span>
										</label>
										<label tabIndex="0" onKeyDown={controlesTarjeta}>
											<input type='radio' name='coms' value='Gobierno' checked={tipoT.tipoT=="Gobierno"} onChange={(e)=>{
												if(e.target.checked){
													setTipoT({tipoT : e.target.value, comision: 7});
												}
											}}/>
											<span>Gobierno</span>
										</label>
									</div>
								</div>
								<div className='totTar'>
									<div>
										Total: $ {totalV + " + $"+(Math.ceil(((totalV/(1-tipoT.comision/100)) - totalV)*100)/100)}
									</div>
									<div>
										Ingresa en la Terminal: $ {Math.ceil(totalV*100/(1-tipoT.comision/100))/100}
									</div>
								</div>
							</>
						}
						<div className='botones'>
							<div className='botonR confirmar' tabIndex={0} onKeyDown={(e)=>{
								if(e.key=="ArrowRight"){
									e.target.nextElementSibling.focus();
								}
								else if(e.key=="ArrowUp"){
									if(tipoP=="tarjeta"){
										document.querySelector(".cambioTarjeta label:first-child").focus();
									}
									else{
										pago.current.focus()
									}
								}
								else if(e.key=="Enter"){
									e.target.click();
								}
							}} onClick={guardar}>Completar</div>
							<div className='cancel' tabIndex={0} onKeyDown={(e)=>{
								if(e.key=="ArrowLeft"){
									e.target.previousElementSibling.focus();
								}
								else if(e.key=="ArrowUp"){
									if(tipoP=="tarjeta"){
										document.querySelector(".cambioTarjeta label:first-child").focus();
									}
									else{
										pago.current.focus()
									}
								}
								else if(e.key=="Enter"){
									e.target.click();
								}
							}} onClick={()=>{
								setModal(false);
							}}>Cancelar</div>
						</div>
					</div>
				}
				{modalB &&
					<div className='busqueda'>
						<div>Ingresa el Código de Barras</div>
						<div className='botones'>
							<input className='inputBasic' placeholder='Ingresa el código' type='text'
							onKeyDown={(e)=>{
								if(e.key == "ArrowDown"){
									document.querySelector(".tablaProductos.busq>div:nth-child(2)") && document.querySelector(".tablaProductos.busq>div:nth-child(2)").focus();
								}
								else if(e.key == "ArrowRight"){
									e.target.nextElementSibling.focus();
								}
								else if(e.key=="Enter"){
									buscarCodigo();
								}
							}}
							autoFocus={true}
							onChange={(e)=>{
								setBuscarP(e.target.value)
							}} value={buscarP}/>
							<span className='botonR' onClick={buscarCodigo}
								tabIndex={0}
								onKeyDown={(e)=>{
									if(e.key == "ArrowDown"){
										document.querySelector(".tablaProductos.busq>div:nth-child(2)") && document.querySelector(".tablaProductos.busq>div:nth-child(2)").focus();
									}
									else if(e.key == "ArrowRight"){
										e.target.nextElementSibling.focus();
									}
									else if(e.key == "ArrowLeft"){
										e.target.previousElementSibling.focus();
									}
									else if(e.key=="Enter"){
										buscarCodigo();
									}
								}}
							>Buscar</span>
							<div className='cancel'
								tabIndex={0}
								onKeyDown={(e)=>{
									if(e.key == "ArrowDown"){
										document.querySelector(".tablaProductos.busq>div:nth-child(2)") && document.querySelector(".tablaProductos.busq>div:nth-child(2)").focus();
									}
									else if(e.key == "ArrowLeft"){
										e.target.previousElementSibling.focus();
									}
									else if(e.key=="Enter"){
										setModalB(false);
									}
								}}
								onClick={()=>{
									setModalB(false);
							}}>Cancelar</div>
						</div>
						<div className='tablaProductos busq'>
							<div className='encabezados'>
								<span></span>
								<span>ID</span>
								<span>Nombre</span>
								<span>Precio</span>
							</div>
							{resultados?.map(resultado=>{
								return(
									<div
										tabIndex={0}
										onKeyDown={controles}
										key={resultado.id_producto}
										onClick={(e)=>{
											if(!resultado?.id_producto)
												return false;
											const prod = productos?.findIndex(prod=>{
												return prod.codigo == resultado.codigo;
											});
											if(prod!==undefined && prod!=-1){
												if(productos[prod].cantidad*1+1 < productos[prod].maximo){
													productos[prod].cantidad = Math.round((productos[prod].cantidad*1 + 1)*100)/100;
													productos[prod].total = Math.round((productos[prod].cantidad*1*productos[prod].precio)*100)/100;
												}
												else{
													productos[prod].cantidad = Math.round((productos[prod].maximo*1)*100)/100;
													productos[prod].total = Math.round((productos[prod].maximo*productos[prod].precio)*100)/100;
												}
											}
											else{
												if(resultado.maximo < 1){
													resultado.cantidad = Math.round(resultado.maximo*100)/100;
													resultado.total = Math.round(resultado.precio*resultado.maximo*100)/100;
												}
												else{
													resultado.cantidad = 1;
													resultado.total = Math.round(resultado.precio*100)/100;
												}
												productos.push(resultado)
											}
											setProductos([...productos]);
											setModalB(false);
										}}
									>
										<span className='imgProd'>
											<img src={resultado.id_archivo ? 'https://www.utopia-tienda.shop/utopia/img/'+resultado.ruta : defaultImg}/>
										</span>
										<span>{resultado?.codigo}</span>
										<span>{resultado?.nombre}</span>
										<span>{resultado?.precio}</span>
									</div>
								);
							})
							}
							{resultados.length === 0 && buscarP!='' &&
								<div>
									<span>Sin resultados. Ningún producto coincide con el código.</span>
								</div>
							}
						</div>
					</div>
				}
				{modalC &&
					<div className='modalChico pCantidad'>
						<div>Ingresa {producto.kilo=='true' ? 'el peso en Kilogramos' : 'la Cantidad'}</div>
						<div>
							<input type='number' className='inputBasic' onKeyDown={(e)=>{
								if(e.key=="Enter"){
									if(producto.cantidad==0){
										setProductos(
											[...productos].filter(prod=>{
												return prod.id_producto!=producto.id_producto
											})
										)
									}
									else{
										if(refCantidad.current.reportValidity()){
											setProductos([...productos])
										}
										else
											return;
									}
									setModalC(false);
								}
								else if(e.key=="ArrowDown"){
									if(producto.kilo == 'true'){
										e.preventDefault();
										refCantidadDinero.current.focus();
									}
								}
							}}
							min={0}
							max={producto.maximo || 0}
							step={producto.kilo == 'true' ? 'any' : '1'}
							autoFocus={true}
							ref={refCantidad}
							onFocus={(e)=>{e.target.select()}}
							onChange={(e)=>{
								producto.cantidad = e.target.value;
								if(producto.kilo == 'true')
									refCantidadDinero.current.value = producto.total = Math.round(e.target.value*producto.precio*100)/100;
							}}
							defaultValue={producto.cantidad || 0}/>
						</div>
						{producto.kilo == 'true' &&
						<>
							<div>Ingresa la Cantidad en dinero</div>
							<div>
								<input type='number' className='inputBasic' onKeyDown={(e)=>{
									if(e.key=="Enter"){
										if(producto.cantidad==0){
											setProductos(
												[...productos].filter(prod=>{
													return prod.id_producto!=producto.id_producto
												})
											)
										}
										else{
											if(refCantidad.current.reportValidity()){
												setProductos([...productos])
											}
											else
												return;
										}
										setModalC(false);
									}
									else if(e.key=="ArrowUp"){
										e.preventDefault();
										refCantidad.current.focus();
									}
								}}
								min={0}
								max={producto.maximo*producto.precio || 0}
								step={producto.kilo == 'true' ? 'any' : '1'}
								autoFocus={true}
								ref={refCantidadDinero}
								onFocus={(e)=>{e.target.select()}}
								onChange={(e)=>{
									refCantidad.current.value = producto.cantidad = Math.round(e.target.value*1000/producto.precio)/1000;
									producto.total = e.target.value;
								}}
								defaultValue={Math.round(producto.cantidad*10*producto.precio)/10 || 0}/>
							</div>
						</>
						}
						<div className='botones'>
							<div className='botonR' onClick={()=>{
								if(refCantidad.current.reportValidity()){
									setProductos([...productos])
									setModalC(false);
								}
							}}>Guardar</div>
							<div className='cancel' onClick={()=>{
								setModalC(false);
							}}>Cancelar</div>
						</div>
					</div>
				}
				</div>
			}
			<div className='contTickets'>
				<div>
					{/*<div className='prueba'>
						<input placeholder='-'/>
						<span>Texto</span>
					</div>*/}
					<div className='top'>
						<label>Ventas</label>
					</div>
					<div className='busque'>
						<div>
							<input className='inputBasic' placeholder='ID Venta' type='text' onChange={(e)=>{
								setBuscar(e.target.value)
							}}/>
						</div>
						<div>
							<div className='botonD' onClick={()=>{
								setInfo({});
								setProductos([]);
							}}>Nueva venta</div>
						</div>
					</div>
					<div className='tablaTickets'>
						<div className='encabezados'>
							<span>ID</span>
							<span>Fecha</span>
							<span>Hora</span>
						</div>
						{ventas?.map((venta)=>{
							let fecha = venta.fecha.split(" ")[0];
							let hora = venta.fecha.split(" ")[1];
							if(buscar=='' || venta.id.toLowerCase().includes(buscar.toLowerCase()))
							return(
								<div key={venta?.id_venta} onClick={()=>{
									setInfo(venta);
								}}>
									<span>{venta?.id}</span>
									<span>{fecha}</span>
									<span>{hora}</span>
								</div>
							)
						})
						}
					</div>
				</div>
				<div>
					<div className='top'>
						<label>Productos de venta</label>
					</div>
					{info?.id_venta &&
						<div>
							<span>
								<span>Fecha:</span>
								<span>{info.fecha.split(" ")[0]}</span>
							</span>
							<span>
								<span>Hora:</span>
								<span>{info.fecha.split(" ")[1]}</span>
							</span>
						</div>
					}
					{!info?.id_venta &&
						<div className='botones'>
							<span className='botonR' onClick={()=>{
								setBuscarP('');
								setResultados([]);
								setModalB(true);
							}}>Buscar Producto</span>
						</div>
					}
					{productos?.length != 0 &&
						<>
							<div className='tablaProductos venT'>
								<div className='encabezados'>
									<span></span>
									<span>ID</span>
									<span>Nombre</span>
									<span>Cantidad</span>
									<span>Precio</span>
									<span>Total</span>
									{!info?.id_venta &&
										<span>Eliminar</span>
									}
								</div>
								{productos?.map(producto=>{
									total["cantidad"]+=producto.cantidad*1;
									if(producto.kilo=='true'){
										total["total"] += Math.round(producto.precio*producto.cantidad);
										producto.total =  Math.round(producto.precio*producto.cantidad);
									}
									else{
										total["total"] += producto.precio*producto.cantidad;
										producto.total =  producto.precio*producto.cantidad;
									}
									return(
										<div key={producto.id_producto}
										tabIndex={0}
										onKeyDown={controles}
										onClick={()=>{
											setProducto(producto);
											setModalC(true)
										}}>
											<span className='imgProd'>
												<img src={producto.id_archivo ? 'https://www.utopia-tienda.shop/utopia/img/'+producto.ruta : defaultImg}/>
											</span>
											<span>{producto?.codigo}</span>
											<span>{producto?.nombre}</span>
											<span>{producto?.cantidad}</span>
											<span>$ {producto?.precio}</span>
											<span>$ {producto?.total}</span>
											{!info?.id_venta &&
												<span className='eliminar' onClick={(e)=>{
													e.stopPropagation()
													if(window.confirm("¿Eliminar '"+producto?.nombre+"' de la venta?"))
														eliminar(e, producto.id_producto)
												}}>Eliminar</span>
											}
										</div>
									);
								})
								}
								{aplicadas?.map(oferta=>{
									return(
										<div key={oferta.id_oferta} className='oferta'>
											<span></span>
											<span>{oferta?.nombre}</span>
											<span></span>
											<span>{oferta?.veces+" x "}</span>
											<span>{"-"+oferta?.desc/oferta?.veces}</span>
											<span>{"-"+oferta?.desc}</span>
										</div>
									);
								})
								}
								<div className='encabezados bottom'>
									<span><div>Total Productos:</div><div> {total["cantidad"]}</div></span>
									<span><div>Total Venta:</div><div> $ {totalV}</div></span>
								</div>
							</div>

							{!info?.id_venta && productos?.length &&
								<div className='botones botMov'>
									<span className='botonR finalizar'
									tabIndex={0} onKeyDown={controles}
									onClick={(e)=>{
										setModal(true);
										e.target.blur();
									}}>Finalizar</span>
									<span className='cancel'
									tabIndex={0} onKeyDown={controles}
									onClick={()=>{
										if(window.confirm("¿Estás seguro que deseas cancelar esta venta?")){
											setProductos([]);
											setOfertas([]);
										}
									}}>Cancelar</span>
								</div>
							}
						</>
					}
					{productos?.length == 0 &&
						<div className='lvacio'>Selecciona una Venta para ver su información o escanea un producto para realizar una nueva venta.</div>
					}
				</div>
			</div>
		</>
	)
}
export default Ventas;