import React, { useState } from 'react';
import useToken from '../useToken';

import usu from '../img/usuariolog.png';
import contra from '../img/contralog.png';

export default function Password() {
  
  const { token, setToken } = useToken();

  const [password, setPassword] = useState();
  const [passwordN, setPasswordN] = useState();
  const [passwordC, setPasswordC] = useState();
  const [showError, setShowError] = useState(false);
  
  function cambiarPass(){
    console.log(passwordC, passwordN);
    if(passwordC === passwordN){
      let data={
        "password": password,
        "passwordN": passwordN,
      }
      console.log(data)
      fetch('https://www.utopia-tienda.shop/utopia/vendedor/cambiaPass.php', {
        method: 'POST',
        headers: {
          'Content-type': 'application/json',
          'Authorization': `Bearer ${token}`, // notice the Bearer before your token
        },
        body: JSON.stringify(data)
      }).then((response) => {/*console.log(response.text());*/return response.json()})
      .then((json) => {
        if(json["errAuth"]){
          setToken({})
          return false;
        }
        if(json["estado"]){
          alert("La contraseña ha sido cambiada con éxito.");
          window.location.href='/';
        }
        else{
          alert("La contraseña no pudo ser cambiada. Verifica que los datos sean correctos");
        }
      })
      .catch((error) => {
        console.log(error);
      });
    }
  }

  return(
    <div>
      <div className='formPass'>
        <div>
          <label>Contraseña Actual</label>
          <input type="password" onChange={e => setPassword(e.target.value)} placeholder='Contraseña Actual'/>
        </div>
        
        <div>
          <label>Contraseña Nueva</label>
          <input type="password" onChange={e => setPasswordN(e.target.value)} placeholder='Contraseña Nueva'/>
        </div>
        
        <div>
          <label>Confirmar Contraseña Actual</label>
          <input type="password" onChange={e => setPasswordC(e.target.value)} placeholder='Confirmar Contraseña Nueva'/>
        </div>
        <div className='botones'>
          <span className='sig' onClick={cambiarPass}>Cambiar contraseña</span>
        </div>
        {showError &&
          <div className='logError'>
            <div>Los datos ingresados no son correctos, comprueba los datos e intentalo de nuevo.</div>
          </div>
        }
      </div>
    </div>
  )
}
