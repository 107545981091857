import React, { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import useToken from '../useToken';
import './Productos.css';
import subir from '../img/subir.png';
import defaultImg from '../img/default.png';

const Productos = ()=>{

	const { token, setToken } = useToken();
	const [buscar, setBuscar] = useState('');
	const [productos, setProductos] = useState([]);
	const fileProd = useRef();
	useEffect(()=>{
		getData();
	},[])

	async function getData(){
		fetch('https://www.utopia-tienda.shop/utopia/vendedor/getProductos.php', 
			{
				headers: {
					'Content-type': 'application/json',
					'Authorization': `Bearer ${token}`, // notice the Bearer before your token
				},
			}
		).then((response) => {/*console.log(response.text());*/return response.json()})
		.then((json) => {
			if(json["errAuth"]){
				// setToken({})
				return false;
			}
			console.log(json);
			setProductos(json["productos"]);
		})
		.catch((error) => {
			console.log(error);
		});
	}
	var codigo = "";
	var timeout = false;
	window.onkeydown = (e)=>{
		if(!timeout){
			timeout = setTimeout(async function(){
				console.log(codigo);
				if(codigo.length>4){
					setBuscar(codigo);
				}
				codigo = "";
				timeout = false;
			}, 100)
		}
		if(e.key.length==1 && e.key.match(/[a-z0-9]/i)){
			codigo+=e.key;
		}
	}
	function filtrar(e){
		if(!e.target.checked){
			document.querySelector(".productos").classList.add(e.target.value);
		}
		else{
			document.querySelector(".productos").classList.remove(e.target.value);
		}
	}
	return(
		<div>
			<div className='bus'>
				<span>
					<input type='text' className='inputBasic' placeholder='Ingresa ID o Nombre' value={buscar||''} onChange={(e)=>{
						setBuscar(e.target.value);
					}}/>
				</span>
			</div>
			<div className='filtros'>
				<label className='lblNormales'>
					<input type='checkbox' onChange={filtrar} value='normales' defaultChecked={true}/>
					<span>Normales</span>
				</label>
				<label className='lblMinimo'>
					<input type='checkbox' onChange={filtrar} value='minimo' defaultChecked={true}/>
					<span>Por Agotar</span>
				</label>
				<label className='lblAgotado'>
					<input type='checkbox' onChange={filtrar} value='agotado' defaultChecked={true}/>
					<span>Agotados</span>
				</label>
				<label className='lblDescontinuado'>
					<input type='checkbox' onChange={filtrar} value='descontinuado' defaultChecked={false}/>
					<span>Descontinuados</span>
				</label>
			</div>
			<div className='productos descontinuado'>
				<div className='encabezados'>
					<span></span>
					<span>Código</span>
					<span>Nombre</span>
					<span>Precio Venta</span>
					<span>Cantidad</span>
				</div>
				{productos?.map(producto=>{
					if(buscar=='' || producto.codigo.toLowerCase().includes(buscar.toLowerCase()) || producto.nombre.toLowerCase().includes(buscar.toLowerCase())){
						console.log(producto.cantidad==null)
						const clase = producto.status=='false' ? "descontinuado" : producto.cantidad==0 || producto.cantidad==null ? "agotado" : producto.minimo*1 >= producto.cantidad*1 ? "minimo" : "";
						return(
							<div className={clase} key={producto.id_producto}>
								<span className='imgProd'>
									<img src={producto.id_archivo ? 'https://www.utopia-tienda.shop/utopia/img/'+producto.ruta : defaultImg}/>
								</span>
								<span>{producto.codigo}</span>
								<span>{producto.nombre}</span>
								<span>{producto.precio}</span>
								<span>{producto.cantidad || 0}</span>
							</div>
						)
					}
				})
				}
			</div>
		</div>
	)
}
export default Productos;