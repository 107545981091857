import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './Login.css';


import usu from '../img/usuariolog.png';
import contra from '../img/contralog.png';

async function loginUser(credentials) {
 return fetch('https://www.utopia-tienda.shop/utopia/vendedor/login.php', {
   method: 'POST',
   headers: {
     'Content-Type': 'application/json'
   },
   body: JSON.stringify(credentials)
 })
   .then(data => data.json())
}

export default function Login({ setToken, modo }) {
  const [usuario, setUsuario] = useState();
  const [password, setPassword] = useState();
  const [showError, setShowError] = useState(false);

  const handleSubmit = async e => {
    e.preventDefault();
    const token = await loginUser({
      usuario,
      password
    });
    if(!token.token){
      setShowError(true);
      setTimeout(function(){
        setShowError(false)
      },3000)
    }
    else
      setToken(token);
  }

  return(
    <div className={"login "+modo}>
      <h1>Inicia Sesión</h1>
      <form onSubmit={handleSubmit}>
        <label>
          <span><img src={usu}/></span><input type="text" onChange={e => setUsuario(e.target.value)} placeholder='Usuario'/>
        </label>
        <label>
          <span><img src={contra}/></span><input type="password" onChange={e => setPassword(e.target.value)} placeholder='Contraseña'/>
        </label>
        <div>
          <button type="submit" className="botbot">Iniciar</button>
        </div>
      </form>
      {showError &&
        <div className='logError'>
          <div>Los datos ingresados no son correctos, comprueba los datos e intentalo de nuevo.</div>
        </div>
      }
    </div>
  )
}

Login.propTypes = {
  setToken: PropTypes.func.isRequired
};