import React, { useEffect, useState } from 'react';
import { BrowserRouter, Route, Routes, NavLink, Outlet } from 'react-router-dom';
import useToken from './useToken';
import './App.css';
import Login from './Login/Login.js';
import Password from './Password/Password.js';
import Inicio from './Inicio/Inicio.js';
import Ventas from './Ventas/Ventas.js';
import Productos from './Productos/Productos.js';
import logo from './img/logoUtopia.svg';
import cerrar from './img/cerrar.png';

function App() {
  const { token, setToken } = useToken();
  console.log(token)
  if(!token) {
    return <Login setToken={setToken}/>
  }
  function logout(){
    fetch('https://www.utopia-tienda.shop/utopia/logout.php', 
      {
        headers: {
          'Content-type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      }
      ).then((response) => {/*console.log(response.text());*/return response.json()})
      .then((json) => {
        console.log(json);
        if(json["estado"]){
          setToken({estado:'cerrar'});
          return false;
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  return (
    <BrowserRouter>
      <div className="contenedor">
        <nav className="navPrin">
          <div>
            <img className='logoIMG' src={logo}/>
          </div>
          <div className='navLink'>
            <NavLink to='/ventas'>Ventas</NavLink>
            <NavLink to='/productos'>Productos</NavLink>
          </div>
          <div>
            <span><img className='imgIcono' onClick={logout} src={cerrar}/></span>
          </div>
        </nav>
        <Routes>
          <Route path="/password" element={<Password/>}></Route>
          <Route path="/">
            <Route path="/ventas/">
              <Route path="" element={<Ventas />}></Route>
            </Route>
            <Route path="/productos/">
              <Route path="" element={<Productos />}></Route>
            </Route>
            {/*<Route path="/productos/" element={<Productos />}></Route>*/}
          </Route>
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;